import { Pivot, PivotItem } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { AskResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { useEffect, useState } from "react";

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: AskResponse;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({ answer, activeTab, activeCitation, citationHeight, className, onActiveTabChanged }: Props) => {
    const isDisabledThoughtProcessTab: boolean = !answer.thoughts;
    const isDisabledSupportingContentTab: boolean = !answer.data_points.length;
    const isDisabledCitationTab: boolean = !activeCitation;

    const sanitizedThoughts = DOMPurify.sanitize(answer.thoughts!);

    const [txtBlob, settxtBlob] = useState<string | null>(null);
    const citaActiva = activeCitation;

    useEffect(() => {

        console.log('La cita activa es:', citaActiva);

        if (activeCitation) {
            fetch(`/api/download-content-blob/${citaActiva}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'text/plain'
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error al obtener el documento');
                }
                const filename = response.headers.get('x-filename'); 
                console.log('Nombre del archivo:', filename);
                return response.blob();
            })
            .then(blob => {
                const txtBlob = new Blob([blob], { type: 'text/plain' });
                const blobUrl = URL.createObjectURL(txtBlob);
                settxtBlob(blobUrl);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
    }, [activeCitation]);

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Thought process"
                headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
            >
                <div className={styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Citation"
                headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}
            >

                {txtBlob && (
                    <object data={txtBlob} type="text/plain" width="100%" height={citationHeight}></object>
                )
                }
            </PivotItem>
        </Pivot>
    );
};
