import { Outlet, NavLink, Link } from "react-router-dom";

import styles from "./Layout.module.css";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="https://www.tenaris.com/es" className={styles.headerTitleContainer}>
                        <img height="80px" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Tenaris_Logo.svg/768px-Tenaris_Logo.svg.png" style={{ padding: '20px' }}></img>
                        <h3 className={styles.headerTitle}></h3>
                    </Link>
                    <nav>
                        {}
                    </nav>
                    <h4 className={styles.headerRightText}>Asistente de soporte IA</h4>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;